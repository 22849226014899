import { useLocation, Redirect } from "react-router-dom";

import PageLayout from "examples/LayoutContainers/PageLayout";
import Footer from "examples/Footer";

import checkExpiredToken from "helpers/checkExpiredToken";

import AddDevice from "./components/AddDevice";

function AddDevices() {
  return (
    <PageLayout>
      <AddDevice />
    </PageLayout>
  );
}

function CheckLogin() {
  const location = useLocation();
  const isLogged = checkExpiredToken();
  return isLogged ? (
    <div style={{ textAlign: "center" }}>
      <AddDevices />
    </div>
  ) : (
    <Redirect
      to={
        "/login?redirect=" +
        location.pathname +
        (location.search !== "" ? `${location.search}` : "")
      }
    />
  );
}

export default CheckLogin;
