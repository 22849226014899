import moment from "moment";

function checkExpiredToken() {
  const timeStampStorage = parseInt(localStorage.getItem("timeStampOut")) || 0;
  let isLoggedStorage = localStorage.getItem("logged");

  if (isLoggedStorage) {
    if (moment().format("x") > timeStampStorage) {
      console.log(">>>>> checkExpiredToken .....:", !(moment().format("x") > timeStampStorage));
      isLoggedStorage = false;
      localStorage.clear();
    }
  }
  return isLoggedStorage;
}

export default checkExpiredToken;
