import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { Toaster, toast } from "react-hot-toast";

// @mui material components
import Grid from "@mui/material/Grid";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import { IoCheckmarkSharp, IoCheckmarkDoneSharp } from "react-icons/io5";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiLabel from "components/VuiLabel";
import VuiInput from "components/VuiInput";
import VuiInputMask from "components/VuiInputMask";
import VuiSelect from "components/VuiSelect";
import VuiButtonCustom from "components/VuiButtonCustom";
import VuiCheckBox from "components/VuiCheckBox";

import Footer from "examples/Footer";

// Images
import Loading from "examples/Icons/Loading";

// Helper
import { validateCPF, validateEmail } from "helpers/utilities";

import useWindowDimensions from "hooks/useWindowDimensions";
import breakpoints from "assets/theme/base/breakpoints";

import colors from "assets/theme/base/colors";

import api, { backEndUrl, frontEndUrl, backImgUrl } from "services/api";

import { Container, Content, TextArea, FlexRow } from "./styles";

const idServiceOrder = uuidv4().split("-");

function VuiRow() {
  return <div style={{ height: 30 }}></div>;
}

function VuiContainer({ children }) {
  return <div style={{ marginTop: 10, width: "100%" }}>{children}</div>;
}

function LoadingServiceOrder() {
  const { height, width } = useWindowDimensions();
  return (
    <VuiBox sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Loading />
    </VuiBox>
  );
}

function FocusNextField(fieldName) {
  const nextfield = document.querySelector(`input[name=${fieldName}]`);
  nextfield.focus();
}

function IsCurrentBoss(office) {
  return office.toUpperCase() === "SUPERVISOR";
}

const notify = (message) =>
  toast.error(message, {
    style: {
      fontSize: 14,
      borderRadius: "5px",
      background: "#333",
      color: "#fff",
    },
  });

const initStates = {
  idOrderService: idServiceOrder[0] + "" + idServiceOrder[1] + "" + idServiceOrder[2],
  dtOrderService: moment().format("DD/MM/YYYY HH:mm"),
  user: "",
  department: "",
  typeService: {},
  problemDescription: "",
  newUserAD: "",
};

const initNewUserActiveDirectory = {
  registration: "",
  fullName: "",
  id_individual_register: "",
  loginName: "",
  eMail: "",
  personaleMail: "",
  departmentAcronym: "",
  phone: "",
  checkUserAccept: false,
};

const initTypeServiceSelect = {
  label: "",
  value: "",
  isBoss: false,
  placeholder: "",
};

function FormServiceOrder({ isMobile, onClose = () => {} }) {
  const userLogged = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(true);
  const [departmentSelected, setDepartmentSelected] = useState("");
  const [isDisabledText, setIsDisabledText] = useState(true);
  const [isDisabledSave, setIsDisabledSave] = useState(false);
  const [lstTypeService, setLstTypeService] = useState(null);
  const [typeServiceSelect, setTypeServiceSelect] = useState(initTypeServiceSelect);
  const [states, setStates] = useState(initStates);
  const [newUserActiveDirectory, setNewUserActiveDirectory] = useState(initNewUserActiveDirectory);

  async function saveSupportCall() {
    delete userLogged.token;
    delete userLogged.hash_reset_email;
    delete userLogged.hash_reset_expire;
    delete userLogged.changePassword;
    delete userLogged.createdAt;
    delete userLogged.updatedAt;
    delete userLogged.lastDateLogon;
    delete userLogged.timeStampOut;
    delete userLogged.dateTimeOut;
    delete userLogged.department;
    delete userLogged.id_initial;
    delete userLogged.initials;
    delete userLogged.id_individual_register;
    delete userLogged.registration_velti;
    delete userLogged.bossRegistry;

    const newOccurrence = {
      ...states,
      user: userLogged,
      newUserAD: states.typeService.value === "NU" ? newUserActiveDirectory : "",
      typeService: { label: states.typeService.label, value: states.typeService.value },
    };

    if (states.typeService.value !== "NU") {
      delete newOccurrence.newUserAD;
    }

    console.log(">>> newOccurrence", newOccurrence);

    const res = await api.post(`occurrence/register`, newOccurrence, {
      headers: { "Content-Type": "application/json", authorization: userLogged.token },
    });

    if (res.data.data.client) {
      console.log(res);
    }
  }

  useEffect(() => {
    async function load() {
      setLoading(true);
      // console.log(">>> userLogged:", userLogged);
      const res = await api.get(`velti/register/?register=${userLogged.registration_velti}`);

      userLogged.department = "";

      if (res.data.found) {
        console.log(">>> res.data:", res.data);
        const departmentSelected = res.data.initials + " - " + res.data.department;
        const isCurrentBoss = IsCurrentBoss(res.data.office);

        setDepartmentSelected(departmentSelected);

        setStates({
          ...states,
          department: departmentSelected,
        });

        setNewUserActiveDirectory({
          ...newUserActiveDirectory,
          departmentAcronym: departmentSelected,
        });

        const supports = await api.get(`types/supports`);
        const newLstTypeService = [];

        supports.data.data.map((i) => {
          const item = {
            label: i.name,
            value: i.value,
            isBoss: i.isBoss,
            caption: i.caption,
            placeholder: i.placeholder,
          };

          if (item.isBoss && isCurrentBoss) {
            newLstTypeService.push(item);
          }

          if (!item.isBoss) {
            newLstTypeService.push(item);
          }
        });
        setLstTypeService(newLstTypeService);
      }
      setLoading(false);
    }

    load();
  }, [false]);

  useEffect(() => {
    // console.log(">>> states.typeService.value:", states.typeService.value);
    if (states.typeService.value === "AU") {
      if (states.problemDescription !== null) setIsDisabledSave(false);
    }
    if (states.typeService.value === "NU") {
      console.log(">>> change new user:", newUserActiveDirectory);
      setIsDisabledSave(false);
    }
  }, [states, newUserActiveDirectory]);

  if (isMobile) {
    return (
      <>
        {typeServiceSelect && typeServiceSelect.value !== "NU" && (
          <>
            <VuiContainer>
              <VuiLabel title="Abertura de Chamado de Suporte" sizeTitle={18} />
            </VuiContainer>
            <VuiRow />

            <VuiContainer>
              <VuiLabel title="Número da Ordem" />
              <VuiInput value={states.idOrderService} disabled />
            </VuiContainer>
            <VuiContainer>
              <VuiLabel title="Data de Abertura" />
              <VuiInput value={states.dtOrderService} disabled />
            </VuiContainer>
            <VuiContainer>
              <VuiLabel title="Requerente do Chamado" />
              <VuiInput
                placeholder="Informe a Contato/Responsável"
                value={userLogged.name}
                disabled
              />
            </VuiContainer>
            <VuiContainer>
              <VuiLabel title="Departamento" />
              <VuiInput value={states.department} disabled />
            </VuiContainer>
            <VuiContainer>
              <VuiSelect
                label="Tipo de Manutenção"
                name={"selectTypeMaintenance"}
                placeholder={isMobile ? "Escolha a Manutenção" : "Informe o Tipo de Manutenção"}
                options={lstTypeService}
                onCustomChange={async (e) => {
                  console.log(">>> lstTypeService:", e);
                  setTypeServiceSelect(e);
                  setIsDisabledText(false);
                  setStates({
                    ...states,
                    typeService: e,
                  });
                }}
              />
            </VuiContainer>

            <VuiContainer>
              <VuiLabel title="Descrição do Problema:" />
              <TextArea
                disabled={isDisabledText}
                rows={8}
                multiline
                name="problemDescription"
                value={states.problemDescription}
                onChange={(e) => {
                  setStates({
                    ...states,
                    problemDescription: e.target.value,
                  });
                }}
                placeholder={
                  typeServiceSelect
                    ? typeServiceSelect.placeholder
                    : "Descreva aqui com todos os detalhes do problema"
                }
              />
            </VuiContainer>
          </>
        )}

        {/*  */}
        {typeServiceSelect && typeServiceSelect.value === "NU" && (
          <>
            <VuiContainer>
              <VuiLabel title="Nome Completo" />
              <VuiInput value={""} />
            </VuiContainer>
            <VuiContainer>
              <VuiLabel title="CPF" />
              <VuiInput value={""} />
            </VuiContainer>
            <VuiContainer>
              <VuiLabel title="Matricula" />
              <VuiInput value={""} />
            </VuiContainer>
            <VuiContainer>
              <VuiLabel title="E-mail Institucional" />
              <VuiInput value={""} />
            </VuiContainer>
          </>
        )}

        <VuiContainer>
          <div style={{ display: "flex", flexDirection: "row", paddingTop: 10 }}>
            <div style={{ width: "50%", paddingRight: 5 }}>
              <VuiButtonCustom
                title={"Fechar"}
                color="dark"
                fontWeight="bold"
                // disabled={isDisabledSave}
                onClick={() => {
                  onClose(true);
                }}
                style={{ width: 300 }}
              />
            </div>
            <div style={{ width: "50%", paddingLeft: 5 }}>
              <VuiButtonCustom
                title="Salvar"
                color="success"
                fontWeight="bold"
                // disabled={isDisabledSave}
                onClick={() => {
                  saveSupportCall();
                }}
              />
            </div>
          </div>
        </VuiContainer>
      </>
    );
  } else {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12} lg={12} xl={12}>
          <VuiLabel title="Abertura de Chamado de Suporte" sizeTitle={32} />
          <VuiRow />

          <Grid container spacing={1}>
            {/*  */}
            {typeServiceSelect && typeServiceSelect.value !== "NU" && (
              <>
                <Grid item xs={12} lg={12} xl={4}>
                  <VuiLabel title="Número da Ordem" />
                  <VuiInput value={states.idOrderService} disabled />
                </Grid>
                <Grid item xs={12} lg={12} xl={4}>
                  <VuiLabel title="Data de Abertura" />
                  <VuiInput value={states.dtOrderService} disabled />
                </Grid>
                <Grid item xs={12} lg={12} xl={4} />

                {/*  */}
                <Grid item xs={12} lg={12} xl={4}>
                  <VuiLabel title="Requerente do Chamado" />
                  <VuiInput
                    placeholder="Informe a Contato/Responsável"
                    value={userLogged ? userLogged.name : ""}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} lg={12} xl={4}>
                  <VuiLabel title="Departamento" />
                  <VuiInput value={states.department} disabled />
                </Grid>

                {/*  */}
                <Grid item xs={12} lg={12} xl={4}>
                  <div style={{ width: isMobile ? "180%" : "100%" }}>
                    <VuiSelect
                      label="Tipo de Manutenção"
                      name={"selectTypeMaintenance"}
                      placeholder={
                        isMobile ? "Escolha a Manutenção" : "Informe o Tipo de Manutenção"
                      }
                      options={lstTypeService}
                      onCustomChange={async (e) => {
                        console.log(">>> lstTypeService:", e);
                        setTypeServiceSelect(e);
                        setIsDisabledText(false);
                        setStates({
                          ...states,
                          typeService: e,
                        });
                      }}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={12} xl={12}>
                  <VuiLabel title="Descrição do Problema:" />
                  <TextArea
                    disabled={isDisabledText}
                    rows={8}
                    multiline
                    name="problemDescription"
                    value={states.problemDescription}
                    onChange={(e) => {
                      setStates({
                        ...states,
                        problemDescription: e.target.value,
                      });
                    }}
                    placeholder={
                      typeServiceSelect
                        ? typeServiceSelect.placeholder
                        : "Descreva aqui com todos os detalhes do problema"
                    }
                  />
                </Grid>
              </>
            )}

            {/*  */}
            {typeServiceSelect && typeServiceSelect.value === "NU" && (
              <>
                <Grid item xs={12} lg={12} xl={12}>
                  <VuiLabel title="Novo Usuário de Domínio" sizeTitle={18} fontWeightTitle="bold" />
                </Grid>
                <Grid item xs={12} lg={12} xl={2}>
                  <VuiLabel title="Matrícula" />
                  <VuiInput
                    placeholder={`Informe a matricula ou deixe em branco`}
                    value={newUserActiveDirectory.registration}
                    onChange={async (e) => {
                      const value = e.target.value;

                      setNewUserActiveDirectory({
                        ...newUserActiveDirectory,
                        registration: value,
                      });

                      console.log(">>> value:length:", value.length);

                      if (value.length > 3) {
                        const res = await api.get(`users/s?boss=${value}`);
                        const dataVelti = res.data.data;

                        console.log(">>> dataVelti:", dataVelti);

                        if (dataVelti.found && !dataVelti.velti) {
                          notify(
                            `Usuário "${dataVelti.user.name.split(" ")[0]} ${
                              dataVelti.user.name.split(" ")[
                                dataVelti.user.name.split(" ").length - 1
                              ]
                            }" já cadastrad${
                              dataVelti.user.gender.substr(0, 1) === "M" ? "o" : "a"
                            }...`
                          );
                        } else if (!dataVelti.found && !dataVelti.velti) {
                          notify(`Usuário não encontrado no VELTI...`);
                          setNewUserActiveDirectory({
                            ...newUserActiveDirectory,
                            registration: value,
                            id_individual_register: "",
                            fullName: "",
                            loginName: "",
                            eMail: "",
                            personaleMail: "",
                          });
                        } else {
                          const loginName =
                            dataVelti.user.name.split(" ")[0].toLowerCase() +
                            "." +
                            dataVelti.user.name
                              .split(" ")
                              [dataVelti.user.name.split(" ").length - 1].toLowerCase()
                              .replace("ç", "c")
                              .replace(/[aáàãâä]/g, "a")
                              .replace(/[eéèêë]/g, "e")
                              .replace(/[iíìîï]/g, "i")
                              .replace(/[oóòõôö]/g, "o")
                              .replace(/[uúùûü]/g, "u");

                          setNewUserActiveDirectory({
                            ...newUserActiveDirectory,
                            registration: value,
                            id_individual_register: dataVelti.user.id_individual_register,
                            fullName: dataVelti.user.name,
                            loginName: loginName,
                            eMail: loginName + "@seap.ma.gov.br",
                            personaleMail: dataVelti.user.email,
                          });
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={12} xl={3}>
                  <VuiLabel title="CPF" />
                  <VuiInputMask
                    placeholder="Informe o CPF"
                    mask="cpf"
                    value={newUserActiveDirectory.id_individual_register}
                    onChange={async (e) => {
                      const value = e.target.value;
                      const lengthValue = value.replace(/\_|\.|\-/g, "").length;

                      setNewUserActiveDirectory({
                        ...newUserActiveDirectory,
                        id_individual_register: value,
                      });

                      if (lengthValue === 11) {
                        if (validateCPF(value.replace(/\_|\.|\-/g, ""))) {
                          const res = await api.get(`users/s?id_individual_register=${value}`);
                          const dataUser = res.data?.data || {};
                          const found = dataUser?.found || false;

                          if (found) {
                            notify(
                              `Usuário "${dataUser.name.split(" ")[0]} ${
                                dataUser.name.split(" ")[dataUser.name.split(" ").length - 1]
                              }" já cadastrad${dataUser.gender.substr(0, 1) === "M" ? "o" : "a"}...`
                            );
                          } else {
                            // FocusNextField("userName");
                            console.log(">>> true found");

                            //
                          }
                        } else {
                          notify(`Número de CPF "${value}" inválido`);
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={12} xl={7}>
                  <VuiLabel title="Nome Completo" />
                  <VuiInput value={newUserActiveDirectory.fullName} />
                </Grid>

                {/* <Grid item xs={12} lg={12} xl={4} />   */}
                <Grid item xs={12} lg={12} xl={3}>
                  <VuiLabel title="login:" />
                  <VuiInput value={newUserActiveDirectory.loginName} />
                </Grid>
                <Grid item xs={12} lg={12} xl={3}>
                  <VuiLabel title="E-mail Institucional" />
                  <VuiInput value={newUserActiveDirectory.eMail} disabled />
                </Grid>
                <Grid item xs={12} lg={12} xl={3}>
                  <VuiLabel title="E-mail pessoal" />
                  <VuiInput
                    value={newUserActiveDirectory.personaleMail}
                    onChange={(e) => {
                      console.log(">>> e", e.target.value);
                      const email = e.target.value;
                      // if (validateEmail(email)) {
                      setNewUserActiveDirectory({
                        ...newUserActiveDirectory,
                        personaleMail: email,
                      });
                      // }
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={12} xl={3}>
                  <VuiLabel title="Número do Celular Whatsapp" />
                  <VuiInputMask
                    name="phone"
                    placeholder="Informe o número do celular"
                    mask="phone"
                    value={newUserActiveDirectory?.phone}
                    onChange={(e) => {
                      setNewUserActiveDirectory({
                        ...newUserActiveDirectory,
                        phone: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={12} xl={12}>
                  <VuiLabel
                    title="Sistemas que o colaborador terá acesso:"
                    sizeTitle={16}
                    fontWeightTitle="bold"
                  />
                </Grid>
                <Grid item xs={12} lg={12} xl={6}>
                  <FlexRow>
                    <IoCheckmarkSharp color={colors.online.main} size={18} />
                    <VuiLabel title={"Rede Unificada SEAP/Estado"} />
                  </FlexRow>
                  <FlexRow>
                    <IoCheckmarkSharp color={colors.online.main} size={18} />
                    <VuiLabel title={"Internet"} />
                  </FlexRow>
                  <FlexRow>
                    <IoCheckmarkSharp color={colors.online.main} size={18} />
                    <VuiLabel title={"Rede Wi-fi SEAP"} />
                  </FlexRow>
                </Grid>
                <Grid item xs={12} lg={12} xl={6}>
                  <FlexRow>
                    <IoCheckmarkSharp color={colors.online.main} size={18} />
                    <VuiLabel title={"Siisp"} />
                  </FlexRow>
                  <FlexRow>
                    <IoCheckmarkSharp color={colors.online.main} size={18} />
                    <VuiLabel title={"Velti"} />
                  </FlexRow>
                </Grid>

                <Grid item xs={12} lg={12} xl={12}></Grid>
                <Grid item xs={12} lg={12} xl={12}>
                  <VuiLabel
                    title="Termo de responsabilidade:"
                    sizeTitle={16}
                    fontWeightTitle="bold"
                  />
                  <VuiLabel title="" sizeTitle={2} sizeSubTitle={2} />
                  <VuiCheckBox
                    label={`EU, ${userLogged.name} colaborador(a) da Secretaria de Estado de Administração Penitenciária – SEAP, como responsável do departamento ${states.department}, certifico que todas as informações são verídicas e o novo cadastro será necessário para o desenvolvimentos dos trabalhos deste setor.`}
                    name="checkUserAccept"
                    value={newUserActiveDirectory.checkUserAccept}
                    onChange={() => {
                      console.log(">>> checkUserAccept:", newUserActiveDirectory.checkUserAccept);
                      setNewUserActiveDirectory({
                        ...newUserActiveDirectory,
                        checkUserAccept: !newUserActiveDirectory.checkUserAccept,
                      });
                    }}
                  />
                </Grid>
              </>
            )}

            {/*  */}
            <Grid item xs={12} lg={12} xl={12} />
            <Grid item xs={12} lg={12} xl={12} />
            <Grid item xs={12} lg={12} xl={12} />
            <Grid item xs={12} lg={12} xl={8} />
            <Grid item xs={12} lg={12} xl={2}>
              <VuiButtonCustom
                title={typeServiceSelect.value === "NU" ? "Cancelar" : "Fechar"}
                color="dark"
                fontWeight="bold"
                // disabled={isDisabledSave}
                onClick={() => {
                  if (typeServiceSelect.value === "NU") {
                    setTypeServiceSelect({
                      label: "",
                      value: "",
                      isBoss: false,
                      placeholder: "",
                    });
                  } else {
                    onClose(true);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} lg={12} xl={2}>
              <VuiButtonCustom
                title="Salvar"
                color="success"
                fontWeight="bold"
                disabled={isDisabledSave}
                onClick={() => {
                  saveSupportCall();
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

function ServiceOrder() {
  const { search } = useLocation();
  const { height, width } = useWindowDimensions();
  const actionCommand = search.replace("?", "").split("=")[0];
  const actionExec = search.replace("?", "").split("=")[1];
  const [loading, setLoading] = useState(true);
  const [onClose, setOnClose] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  const history = useHistory();

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  useEffect(() => {
    console.log(">>> actionCommand:", actionCommand);
    console.log(">>>    actionExec:", actionExec);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [false]);

  useEffect(() => {
    if (onClose) {
      localStorage.clear();
      history.push("/login?redirect=/support");
    }
  }, [onClose]);

  return (
    <Container>
      <Toaster position="top-right" reverseOrder={false} />
      <Content width={isMobileView ? 90 : 60} p={isMobileView ? 0.5 : 2.25}>
        {loading ? (
          <LoadingServiceOrder />
        ) : (
          <FormServiceOrder
            isMobile={isMobileView}
            onClose={() => {
              setOnClose(true);
            }}
          />
        )}
      </Content>
      <Footer />
    </Container>
  );
}

export default ServiceOrder;
