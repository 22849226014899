import styled from "styled-components";

const useResolveColorTheme = ({ light, dark }) => {
  return dark;
};

export const Container = styled.div`
  input[type="radio"]:checked,
  input[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  input[type="checkbox"]:checked + label,
  input[type="checkbox"]:not(:checked) + label {
    /* position: relative; */
    padding-left: 8px;
    /* cursor: pointer; */
    /* line-height: 20px; */
    /* display: inline-block; */
    color: ${() => useResolveColorTheme({ light: "#7A7D8B", dark: "#ffff" })};
  }

  input[type="radio"]:checked {
    color: ${() => useResolveColorTheme({ light: "#6a49d9", dark: "#2152ff" })};
  }

  input[type="radio"]:checked + label:before,
  input[type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #bdc1cb;
    border-radius: 100%;
    background: #fff;
  }

  input[type="radio"]:checked + label:before {
    border: 1px solid ${() => useResolveColorTheme({ light: "#6a49d9", dark: "#2152ff" })};
  }

  input[type="radio"]:checked + label:after,
  input[type="radio"]:not(:checked) + label:after {
    content: "";
    width: 12px;
    height: 12px;
    background: ${() => useResolveColorTheme({ light: "#6a49d9", dark: "#2152ff" })};
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  input[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  input[type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  label {
    font-size: 14px;
    color: ${() => useResolveColorTheme({ light: "#7A7D8B", dark: "#ffff" })};
  }
`;
