import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { Toaster, toast } from "react-hot-toast";

// @mui material components
import Grid from "@mui/material/Grid";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import InputBase from "@mui/material/InputBase";

import { IoCheckmarkSharp, IoCheckmarkDoneSharp } from "react-icons/io5";

// import { Html5QrcodeScanner } from "html5-qrcode";
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiLabel from "components/VuiLabel";
import VuiInput from "components/VuiInput";
import VuiInputMask from "components/VuiInputMask";
import VuiSelect from "components/VuiSelect";
import VuiButtonCustom from "components/VuiButtonCustom";
import VuiCheckBox from "components/VuiCheckBox";

import Footer from "examples/Footer";

// Images
import Loading from "examples/Icons/Loading";

import FormDevices from "../FormDevices";

// Helper
import { validateCPF, validateEmail } from "helpers/utilities";

import useWindowDimensions from "hooks/useWindowDimensions";
import breakpoints from "assets/theme/base/breakpoints";

import colors from "assets/theme/base/colors";

import api, { backEndUrl, frontEndUrl, backImgUrl } from "services/api";

import { Container, Content, TextArea, FlexRow } from "./styles";

function LoadingServiceOrder() {
  const { height, width } = useWindowDimensions();
  return (
    <VuiBox
      sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: width - 200 }}
    >
      <Loading />
    </VuiBox>
  );
}

function FocusNextField(fieldName) {
  const nextfield = document.querySelector(`input[name=${fieldName}]`);
  nextfield.focus();
}

const notify = (message) =>
  toast.error(message, {
    style: {
      fontSize: 14,
      borderRadius: "5px",
      background: "#333",
      color: "#fff",
    },
  });

function AddDevice() {
  const { search } = useLocation();
  const { height, width } = useWindowDimensions();
  const actionCommand = search.replace("?", "").split("=")[0];
  const actionExec = search.replace("?", "").split("=")[1];
  const [loading, setLoading] = useState(true);
  const [onClose, setOnClose] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  const history = useHistory();

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [false]);

  useEffect(() => {
    if (onClose) {
      localStorage.clear();
      history.push("/login?redirect=/devices");
    }
  }, [onClose]);

  if (isMobileView) {
    return (
      <>
        <Toaster position="top-right" reverseOrder={false} />
        <>
          {loading ? (
            <Container>
              <LoadingServiceOrder />
              <Footer />
            </Container>
          ) : (
            <>
              <FormDevices
                isMobile={isMobileView}
                onClose={() => {
                  setOnClose(true);
                }}
              />
              <Footer />
            </>
          )}
        </>
      </>
    );
  }

  return (
    <Container>
      <Toaster position="top-right" reverseOrder={false} />
      {loading ? (
        <LoadingServiceOrder />
      ) : (
        <FormDevices
          isMobile={isMobileView}
          onClose={() => {
            setOnClose(true);
          }}
        />
      )}
      {loading ? null : <Footer />}
    </Container>
  );
}

export default AddDevice;
