import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { Toaster, toast } from "react-hot-toast";

// @mui material components
import Grid from "@mui/material/Grid";
import moment from "moment";

import { v4 as uuidv4 } from "uuid";

import { IoCheckmarkSharp, IoCheckmarkDoneSharp } from "react-icons/io5";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiLabel from "components/VuiLabel";
import VuiInput from "components/VuiInput";
import VuiInputMask from "components/VuiInputMask";
import VuiSelect from "components/VuiSelect";
import VuiButtonCustom from "components/VuiButtonCustom";
import VuiCheckBox from "components/VuiCheckBox";
import { ViuCircularProgress } from "components/VuiCircularWithValueLabel";

import Footer from "examples/Footer";

// Images
import Loading from "examples/Icons/Loading";

// Helper
import { validateCPF, validateEmail } from "helpers/utilities";

import useWindowDimensions from "hooks/useWindowDimensions";
import breakpoints from "assets/theme/base/breakpoints";

import colors from "assets/theme/base/colors";

import api, { backEndUrl, frontEndUrl, backImgUrl } from "services/api";

import { Container, Content, TextArea, FlexRow } from "./styles";

const typeDevices = [
  { value: 1, label: "Computador", type: "ActiveLan" },
  { value: 2, label: "Notebook", type: "ActiveLan" },
  { value: 3, label: "Impressora", type: "ActiveLan" },
  { value: 4, label: "Switch Gerenciavel", type: "ActiveLan" },
  { value: 5, label: "Roteador", type: "ActiveLan" },
  { value: 6, label: "RouterBoard", type: "ActiveLan" },
  { value: 7, label: "DRV", type: "ActiveLan" },
  { value: 8, label: "NVR", type: "ActiveLan" },
  { value: 9, label: "Camera IP", type: "ActiveLan" },
  { value: 10, label: "Relogio de Ponto", type: "ActiveLan" },
  { value: 11, label: "Aparelho VoIP", type: "ActiveLan" },
  { value: 12, label: "SmartTV", type: "ActiveLan" },
  { value: 13, label: "SmartPhone", type: "ActiveLan" },
  { value: 14, label: "Tablet / IPad", type: "ActiveLan" },
  { value: 15, label: "Nobreak", type: "NoActiveLan" },
  { value: 16, label: "Estabelizador", type: "NoActiveLan" },
  { value: 17, label: "Modulo Isolador", type: "NoActiveLan" },
  { value: 18, label: "Outros não listados", type: "NoActiveLan" },
];

function VuiRow() {
  return <div style={{ height: 30 }}></div>;
}

function VuiContainer({ children }) {
  return <div style={{ marginTop: 10, width: "100%" }}>{children}</div>;
}

function createIdDeviceValid() {
  return (
    "1:" +
    "X:XX:XX:XX:XX:XX".replace(/X/g, () => {
      return "0123456789ABCDEF".charAt(Math.floor(Math.random() * 16));
    })
  );
}

function checkMacAddressIsValid(macAddress) {
  if (macAddress === "11:11:11:11:11:11") return false;
  if (macAddress === "22:22:22:22:22:22") return false;
  if (macAddress === "33:33:33:33:33:33") return false;
  if (macAddress === "44:44:44:44:44:44") return false;
  if (macAddress === "55:55:55:55:55:55") return false;
  if (macAddress === "66:66:66:66:66:66") return false;
  if (macAddress === "77:77:77:77:77:77") return false;
  if (macAddress === "88:88:88:88:88:88") return false;
  if (macAddress === "99:99:99:99:99:99") return false;
  if (macAddress.replaceAll(/:/gi, "") === "A".repeat(12)) return false;
  if (macAddress.replaceAll(/:/gi, "") === "B".repeat(12)) return false;
  if (macAddress.replaceAll(/:/gi, "") === "C".repeat(12)) return false;
  if (macAddress.replaceAll(/:/gi, "") === "D".repeat(12)) return false;
  if (macAddress.replaceAll(/:/gi, "") === "E".repeat(12)) return false;
  if (macAddress.replaceAll(/:/gi, "") === "F".repeat(12)) return false;

  let regex = new RegExp(
    /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}.[0-9a-fA-F]{4}.[0-9a-fA-F]{4})$/
  );

  if (macAddress == null) {
    return "false";
  }

  if (regex.test(macAddress) === true) {
    return "true";
  } else {
    return "false";
  }
}

const notify = (message) =>
  toast.error(message, {
    style: {
      fontSize: 14,
      borderRadius: "5px",
      background: "#333",
      color: "#fff",
    },
  });

//  98 98805 6015

function FocusNextField(fieldName) {
  console.log(">>> fieldName:", fieldName);
  const nextfield = document.querySelector(`input[name=${fieldName}]`);
  nextfield.focus();
}

function FormDevices({ isMobile, onClose = () => {} }) {
  const userLogged = JSON.parse(localStorage.getItem("user"));

  const [loading, setLoading] = useState(true);

  // List VuiSelect's.
  const [lstTypeDevices, setLstTypeDevices] = useState([]);
  const [lstDepartment, setLstDepartment] = useState([]);

  // List var selected.
  const [deviceSelected, setDeviceSelected] = useState("");
  const [isDisabledText, setIsDisabledText] = useState(true);
  const [isDisabledSave, setIsDisabledSave] = useState(true);
  const [isGetIpAddress, setIsGetIpAddress] = useState(false);
  const [isFinishProcess, setIsFinishProcess] = useState(false);
  const [isErrorProcess, setIsErrorProcess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [states, setStates] = useState({
    macAddress: "",
    idDevice: "0:0:00:00:00:00:00",
    typeDevice: null,
    brand: "",
    model: "",
    patrimony: "",
    department: null,
    problemDescription: "",
    ipAddress: "",
    mask: "",
    gateway: "",
    dnsPrimary: "",
    dnsSecondary: "",
    createdAt: moment().format("DD/MM/YYYY"),
    updatedAt: moment().format("DD/MM/YYYY"),
    user: userLogged,
  });

  useEffect(() => {
    async function loadUnits() {
      setLoading(true);
      const res = await api.get(`velti/departments`);

      if (res.data.length > 0) {
        setLstDepartment(
          res.data.map((i) => {
            return {
              value: i.id,
              label: i.acronym + " - " + i.department,
              acronym: i.acronym,
              unit: i.unit,
            };
          })
        );

        setStates({
          ...states,
          department: res.data[0].acronym + " - " + res.data[0].department,
        });
      }
      setLoading(false);
    }

    async function loadTypeDevices() {
      setLoading(true);
      const res = await api.get(`types/devices`);

      if (res.data.length > 0) {
        setLstTypeDevices(
          res.data.map((i) => {
            return { value: i.id, label: i.name, type: i.type };
          })
        );

        setStates({
          ...states,
          typeDevice: res.data[0].id + " - " + res.data[0].name,
        });
      }
      setLoading(false);
    }

    loadUnits();
    loadTypeDevices();
  }, [false]);

  function saveDevice() {
    setIsDisabledSave(true);
    setIsGetIpAddress(true);
  }

  async function saveInfoDevice() {
    if (states.macAddress === "" && states.typeDevice.value >= 14) {
      states.idDevice = createIdDeviceValid();
    }
    const res = await api.post(`devices/register`, states, {
      headers: { "Content-Type": "application/json", authorization: userLogged.token },
    });

    console.log(">>> states:res:", res.data.data);
    if (res.data.data.client === "Error") {
      setIsErrorProcess(true);
      setErrorMessage(res.data.data.message);
    } else {
      setStates(res.data.data);
    }
  }

  function resetForm() {
    setStates({
      macAddress: "",
      idDevice: "0:0:00:00:00:00:00",
      typeDevice: null,
      brand: "",
      model: "",
      patrimony: "",
      department: null,
      problemDescription: "",
      ipAddress: "",
      mask: "",
      gateway: "",
      dnsPrimary: "",
      dnsSecondary: "",
      createdAt: moment().format("DD/MM/YYYY"),
      updatedAt: moment().format("DD/MM/YYYY"),
      user: userLogged,
    });
    setIsFinishProcess(false);
  }

  // npx update-browserslist-db@latest

  if (isErrorProcess) {
    return (
      <Content p={2.5}>
        <div style={{ display: "flex", flexDirection: "column", padding: isMobile ? 20 : 0 }}>
          <VuiContainer>
            <VuiLabel title={errorMessage} sizeTitle={24} />
            <VuiLabel title="" sizeTitle={4} />
            <VuiLabel title="" sizeTitle={4} />
          </VuiContainer>

          <div style={{ display: "flex", flexDirection: "row", paddingTop: 10 }}>
            <div style={{ width: "50%", paddingRight: 5 }} />
            <div style={{ width: "25%", paddingRight: 5 }} />
            <div style={{ width: "25%", paddingLeft: 5 }}>
              <VuiButtonCustom
                title="Fechar"
                color="warning"
                fontWeight="bold"
                onClick={() => {
                  setIsErrorProcess(false);
                  setErrorMessage("");
                  setIsFinishProcess(false);
                  setIsGetIpAddress(false);
                }}
              />
            </div>
          </div>
        </div>
      </Content>
    );
  }

  if (isFinishProcess) {
    return (
      <Content p={isMobile ? 0.5 : 2.5} style={{ margin: 10, marginTop: 160, marginBottom: 60 }}>
        <div style={{ display: "flex", flexDirection: "column", padding: isMobile ? 10 : 0 }}>
          {states.client === "STATIC" ? (
            <>
              <VuiContainer>
                <VuiLabel title="Equipameto Cadastrado" sizeTitle={24} />
                <VuiLabel title="" />
                <VuiLabel title={`Localidade: ${states.department.label}`} sizeTitle={14} />
                <VuiLabel title={`Configuração IP (${states.typeDevice.label}):`} sizeTitle={14} />
              </VuiContainer>

              <VuiContainer>
                <VuiLabel title="Endereço IP:" />
                <VuiInput value={states.ipAddress} />
              </VuiContainer>

              <VuiContainer>
                <VuiLabel title="MacAddress:" />
                <VuiInput value={states.macAddress} />
              </VuiContainer>

              <VuiContainer>
                <VuiLabel title="Mascára Sub-Rede:" />
                <VuiInput value={states.mask} />
              </VuiContainer>

              <VuiContainer>
                <VuiLabel title="Gateway:" />
                <VuiInput value={states.gateway} />
              </VuiContainer>

              <VuiContainer>
                <VuiLabel title="DNS Preferencial:" />
                <VuiInput value={states.dnsPrimary} />
              </VuiContainer>

              <VuiContainer>
                <VuiLabel title="DNS Alternativo:" />
                <VuiInput value={states.dnsSecondary} />
              </VuiContainer>
            </>
          ) : null}

          {/*  */}
          {states.client === "DHCP" ? (
            <>
              <VuiContainer>
                <VuiLabel
                  title="Equipameto Cadastrado"
                  sizeTitle={isMobile ? 22 : 24}
                  fontWeightTitle="bold"
                />
                <VuiLabel title="" />

                <VuiLabel
                  title={`Localidade:`}
                  sizeTitle={isMobile ? 14 : 14}
                  fontWeightTitle="bold"
                  subtitle={`${states.department.label}`}
                  sizeSubTitle={isMobile ? 14 : 14}
                />
                <VuiLabel title="" />

                {isMobile ? (
                  <>
                    <VuiLabel
                      title={`Configuração IP: `}
                      sizeTitle={isMobile ? 14 : 14}
                      fontWeightTitle="bold"
                    />
                    <VuiLabel
                      title={`Será atribuido um IP automaticamente pelo Servidor DHCP.`}
                      sizeTitle={isMobile ? 14 : 14}
                    />
                  </>
                ) : (
                  <VuiLabel
                    title={`Configuração IP: `}
                    sizeTitle={isMobile ? 12 : 14}
                    subtitle={`Será atribuido um IP automaticamente pelo Servidor DHCP.`}
                    fontWeightSubTitle="bold"
                    sizeSubTitle={isMobile ? 10 : 14}
                  />
                )}
              </VuiContainer>

              <VuiLabel title="" />
            </>
          ) : null}

          {/*  */}
          {states.client === "NOACTIVELAN" ? (
            <>
              <VuiContainer>
                <VuiLabel
                  title="Equipameto Cadastrado"
                  sizeTitle={isMobile ? 22 : 24}
                  fontWeightTitle="bold"
                />
                <VuiLabel title="" />

                <VuiLabel
                  title={`Departamento:`}
                  sizeTitle={isMobile ? 14 : 14}
                  fontWeightTitle="bold"
                  subtitle={`${states.department.label}`}
                  sizeSubTitle={isMobile ? 14 : 14}
                />
                <VuiLabel title="" />

                <VuiLabel
                  title={`Equipamento cadastro com sucesso. Código `}
                  sizeTitle={isMobile ? 14 : 14}
                  subtitle={`${states.idDevice}`}
                  fontWeightSubTitle="bold"
                />
                <VuiLabel title="" />
                <VuiLabel
                  title={` Clique no botão Fechar para continuar.`}
                  sizeTitle={isMobile ? 14 : 14}
                />
              </VuiContainer>

              <VuiLabel title="" />
            </>
          ) : null}

          <div style={{ display: "flex", flexDirection: "row", paddingTop: 10 }}>
            <div style={{ width: "50%", paddingRight: 5 }} />
            <div style={{ width: "25%", paddingRight: 5 }} />
            <div style={{ width: "25%", paddingLeft: 5 }}>
              <VuiButtonCustom
                title="Fechar"
                color="dark"
                fontWeight="bold"
                onClick={() => {
                  resetForm();
                }}
              />
            </div>
          </div>
        </div>
      </Content>
    );
  }

  if (isGetIpAddress) {
    return (
      <Content p={2.5}>
        <div style={{ marginTop: isMobile ? 200 : 0, marginBottom: isMobile ? 200 : 0 }}>
          <ViuCircularProgress
            timeOut={50}
            height={180}
            label={[
              { min: 1, max: 33, value: 1, msg: "Aguarde... Salvando informações" },
              { min: 34, max: 66, value: 50, msg: "Aguarde... Verificando qual IP disponível.." },
              { min: 67, max: 100, value: 100, msg: "Aguarde... Finalizando o processo" },
            ]}
            onExec={() => {
              // setLoadingSave(false);
            }}
            onProgress={async (n) => {
              {
                if (n === 15) {
                  console.log(">>> Salvar informações aqui", n);
                  await saveInfoDevice();
                }
                if (n === 50) {
                  console.log(">>> Verificando qual IP disponível", n);
                  // const result = await createPgp(resultSave);
                }
                if (n === 85) {
                  console.log(">>> Finalizando processo", n);
                }
                if (n >= 99) {
                  setIsFinishProcess(true);
                  setIsGetIpAddress(false);
                }
              }
            }}
          />
        </div>
      </Content>
    );
  }
  //
  // View Mobile
  //
  if (isMobile) {
    return (
      <div style={{ margin: 10, borderRadius: 5, padding: 10, background: colors.grey[700] }}>
        <VuiContainer>
          <VuiLabel title="Cadastro de Equipameto" sizeTitle={24} />
          <VuiRow />
        </VuiContainer>
        <VuiContainer>
          <VuiLabel title="Endereço MAC:" />
          <VuiInputMask
            mask="MAC"
            placeholder="Informe a Contato/Responsável"
            value={states.macAddress}
            onChange={async (e) => {
              const idMacAddress = e.target.value.toUpperCase();
              const idDevice = "1:" + idMacAddress.substring(1);

              const res = await api.get(`devices/s?mac=${idMacAddress}`);

              console.log(
                ">>> AQUI idDevice, idMacAddress:",
                idDevice,
                idMacAddress,
                ">>> res:",
                res.data
              );

              setStates({
                ...states,
                macAddress: idMacAddress,
                idDevice: idDevice,
              });
            }}
          />
        </VuiContainer>

        <VuiContainer>
          <VuiLabel title="ID do Equipamento:" />
          <VuiInput value={states.idDevice} disabled />
        </VuiContainer>

        <VuiContainer>
          <VuiLabel title="Data de Inclusão" />
          <VuiInput value={states.createdAt} disabled />
        </VuiContainer>

        <VuiContainer>
          <VuiLabel title="Tipo do Equipameto:" />
          <VuiSelect
            // label="Tipo do Equipameto:"
            name={"selectTypeDevice"}
            placeholder="Qual equipamento..."
            options={lstTypeDevices}
            onCustomChange={async (e) => {
              console.log(">>> lstTypeDevices:", e);
              setDeviceSelected(e);
              setIsDisabledText(false);
              setStates({
                ...states,
                typeDevice: e,
              });
            }}
          />
        </VuiContainer>

        <VuiContainer>
          <VuiLabel title="Marca:" />
          <VuiInput
            placeholder="Informe a Marca"
            value={states.brand}
            onChange={(e) => {
              console.log(e.target.value);
              setStates({
                ...states,
                brand: e.target.value,
              });
            }}
          />
        </VuiContainer>

        <VuiContainer>
          <VuiLabel title="Modelo:" />
          <VuiInput
            placeholder="Informe o Modelo"
            value={states.model}
            onChange={(e) => {
              console.log(e.target.value);
              setStates({
                ...states,
                model: e.target.value,
              });
            }}
          />
        </VuiContainer>

        <VuiContainer>
          <VuiLabel title="Patrimonio:" />
          <VuiInput
            placeholder="Informe o Número do Patrimonio"
            value={states.patrimony}
            onChange={(e) => {
              console.log(e.target.value);
              setStates({
                ...states,
                patrimony: e.target.value,
              });
            }}
          />
        </VuiContainer>

        {/*  */}
        <VuiContainer>
          <VuiSelect
            label="Departamento:"
            name={"selectDepartment"}
            placeholder="Informe o Departamento..."
            options={lstDepartment}
            onCustomChange={async (e) => {
              console.log(">>> lstDepartment:", e);
              setStates({
                ...states,
                department: e,
              });
            }}
          />
        </VuiContainer>

        {/*  */}
        <VuiContainer>
          <VuiLabel title="Coméntario(s):" />
          <TextArea
            // disabled={isDisabledText}
            rows={8}
            multiline
            name="problemDescription"
            value={states.problemDescription}
            onChange={(e) => {
              setStates({
                ...states,
                problemDescription: e.target.value,
              });
            }}
            placeholder={"Informe algum comentário sobre este equipamento..."}
          />
        </VuiContainer>

        <div style={{ display: "flex", flexDirection: "row", paddingTop: 10 }}>
          <div style={{ width: "50%", paddingRight: 5 }}>
            <VuiButtonCustom
              title={"Fechar"}
              color="dark"
              fontWeight="bold"
              // disabled={isDisabledSave}
              onClick={() => {
                onClose(true);
              }}
              style={{ width: 300 }}
            />
          </div>
          <div style={{ width: "50%", paddingLeft: 5 }}>
            <VuiButtonCustom
              title="Salvar"
              color="success"
              fontWeight="bold"
              // disabled={isDisabledSave}
              onClick={() => {
                setIsDisabledSave(true);
                saveDevice();
              }}
            />
          </div>
        </div>
      </div>
    );
  }
  //
  // View Web
  //
  return (
    <Content width={60} p={2}>
      <Grid container spacing={0}>
        <Grid item xs={12} lg={12} xl={12}>
          <VuiLabel title="Cadastro de Equipameto" sizeTitle={32} />
          <VuiRow />

          <Grid container spacing={1}>
            {/*  */}
            <Grid item xs={12} lg={12} xl={3}>
              <VuiLabel title="Endereço MAC:" />
              <VuiInputMask
                mask="MAC"
                placeholder="Informe o MacAddress"
                value={states.macAddress}
                onChange={async (e) => {
                  const idMacAddress = e.target.value.toUpperCase();
                  const idDevice = "1:" + idMacAddress.substring(1).toUpperCase();

                  setStates({
                    ...states,
                    macAddress: idMacAddress,
                    idDevice: idDevice,
                  });

                  if (idMacAddress.length === 17) {
                    if (!checkMacAddressIsValid(idMacAddress)) {
                      notify("MacAddress não valido!");
                      return;
                    }

                    if (idMacAddress !== "00:00:00:00:00:00") {
                      const res = await api.get(`devices/s?mac=${idMacAddress}`);
                      const isFoundMacAddress = res.data.data.length > 0;

                      if (isFoundMacAddress) {
                        notify("MacAddress já cadastrado");
                        return;
                      }
                    } else {
                      setStates({
                        ...states,
                        macAddress: idMacAddress,
                        idDevice: createIdDeviceValid(),
                      });
                    }
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} lg={12} xl={3}>
              <VuiLabel title="ID do Equipamento:" />
              <VuiInput value={states.idDevice} disabled />
            </Grid>

            <Grid item xs={12} lg={12} xl={3}>
              <VuiLabel title="Data de Inclusão" />
              <VuiInput value={states.createdAt} disabled />
            </Grid>

            <Grid item xs={12} lg={12} xl={3} />

            {/*  */}
            <Grid item xs={12} lg={12} xl={3}>
              <VuiLabel title="Tipo do Equipameto:" />
              <VuiSelect
                name={"selectTypeDevice"}
                placeholder="Qual equipamento..."
                options={lstTypeDevices}
                onCustomChange={async (e) => {
                  console.log(">>> lstTypeDevices:", e);
                  setDeviceSelected(e);
                  setIsDisabledText(false);
                  setStates({
                    ...states,
                    typeDevice: e,
                    idDevice:
                      e.type === "NoActiveLan"
                        ? createIdDeviceValid()
                        : states.macAddress === ""
                        ? "0:0:00:00:00:00:00"
                        : states.idDevice,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} lg={12} xl={3}>
              <VuiLabel title="Marca:" />
              <VuiInput
                placeholder="Informe a Marca"
                value={states.brand}
                onChange={(e) => {
                  console.log(e.target.value);
                  setStates({
                    ...states,
                    brand: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} lg={12} xl={3}>
              <VuiLabel title="Modelo:" />
              <VuiInput
                placeholder="Informe o Modelo"
                value={states.model}
                onChange={(e) => {
                  console.log(e.target.value);
                  setStates({
                    ...states,
                    model: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} lg={12} xl={3}>
              <VuiLabel title="Patrimonio:" />
              <VuiInput
                placeholder="Informe o Número do Patrimonio"
                value={states.patrimony}
                onChange={(e) => {
                  console.log(e.target.value);
                  setStates({
                    ...states,
                    patrimony: e.target.value,
                  });
                }}
              />
            </Grid>

            {/*  */}
            <Grid item xs={12} lg={12} xl={12}>
              <VuiLabel title="Departamento:" />
              <VuiSelect
                name={"selectDepartment"}
                placeholder="Informe o Departamento..."
                options={lstDepartment}
                onCustomChange={async (e) => {
                  console.log(">>> lstDepartment:", e);
                  setStates({
                    ...states,
                    department: e,
                  });
                }}
              />
            </Grid>

            {/*  */}
            <Grid item xs={12} lg={12} xl={12}>
              <VuiLabel title="Coméntario(s):" />
              <TextArea
                // disabled={isDisabledText}
                rows={8}
                multiline
                name="problemDescription"
                value={states.problemDescription}
                onChange={(e) => {
                  setStates({
                    ...states,
                    problemDescription: e.target.value,
                  });
                }}
                placeholder={"Informe algum comentário sobre este equipamento..."}
              />
            </Grid>

            {/*  */}
            <Grid item xs={12} lg={12} xl={12} />
            <Grid item xs={12} lg={12} xl={8} />
            <Grid item xs={12} lg={12} xl={2}>
              <VuiButtonCustom
                title={"Fechar"}
                color="dark"
                fontWeight="bold"
                onClick={() => {
                  onClose(true);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={12} xl={2}>
              <VuiButtonCustom
                title="Salvar"
                color="success"
                fontWeight="bold"
                // disabled={isDisabledSave}
                onClick={() => {
                  saveDevice();
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Content>
  );
}
export default FormDevices;
