import styled, { css } from "styled-components";

import colors from "assets/theme/base/colors";

const useResolveColorTheme = ({ light, dark }) => {
  return dark;
};

export const Container = styled.article`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  background-color: white;
  width: ${(props) => props.width}%;
  background: ${() =>
    useResolveColorTheme({ light: "rgba(255, 255, 255, 0.15)", dark: colors.grey[700] })};
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.p}rem;
`;

export const TextArea = styled.textarea`
  border-radius: 15px;
  border-color: ${colors.inputColors.borderColor.main};
  background-color: ${colors.inputColors.backgroundColor};
  color: ${colors.white.main};
  width: 100%;
  padding: 5px;
  font-family: "Montserrat", sans-serif;
`;
