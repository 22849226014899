// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

import appVersion from "../../../package.json";

function Footer() {
  return (
    <VuiBox
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="center"
      direction="row"
      component="footer"
      py={2}
      pb={0}
    >
      <VuiBox item xs={12} sx={{ textAlign: "center" }}>
        <VuiTypography
          variant="button"
          sx={{ textAlign: "center", fontWeight: "800 !important" }}
          color="white"
        >
          Todos os direitos @ 2023-2024 - versão {appVersion.version.substring(0, 9)} - Developer by
          MAVO® - Licensed to SRT/SAAME/SEAP
        </VuiTypography>
      </VuiBox>
    </VuiBox>
  );
}

export default Footer;
