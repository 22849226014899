import { useState, useEffect } from "react";
import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function CircularProgressWithLabel({ label, value, ...props }) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {value ? (
            <Typography variant="caption" component="div" color="text.main">{`${Math.round(
              value
            )}%`}</Typography>
          ) : (
            <></>
          )}
          {label && (
            <Typography
              mt={1}
              textAlign={"center"}
              variant="caption"
              component="div"
              color="text.main"
            >
              {label}
            </Typography>
          )}
        </div>
      </Box>
    </Box>
  );
}

export function ViuCircularProgress({ label, timeOut = 100, height = 600, onExec, onProgress }) {
  const [progress, setProgress] = useState(1);
  let msgLabel = "";

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        onProgress(prevProgress);
        return prevProgress >= 101 ? 1 : prevProgress + 1;
      });
    }, timeOut);
    return () => {
      clearInterval(timer);
    };
  }, []);

  if (progress === 100) {
    onExec();
  }

  const strLabel = label.filter((x) => {
    if (progress >= x.min && progress <= x.max) {
      return true;
    }
  });

  msgLabel = strLabel.length > 0 ? strLabel[0].msg : msgLabel;

  return (
    <div
      style={{ display: "flex", justifyContent: "center", alignItems: "center", height: height }}
    >
      <CircularProgressWithLabel label={msgLabel} value={progress} size="160px" thickness={0.5} />
    </div>
  );
}

export default function VuiCircularWithValueLabel({ ...props }) {
  return <CircularProgressWithLabel {...props} />;
}
