import React, { InputHTMLAttributes } from "react";

import { Container } from "./styles";

const VuiCheckBox = ({ label, name, ...props }) => {
  return (
    <Container className="containerRadio">
      <p>
        <input type="checkbox" id={name} name={name} {...props} />
        <label htmlFor={name}>{label}</label>
      </p>
    </Container>
  );
};

export default VuiCheckBox;
