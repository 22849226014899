/** 
  All of the routes for the Vision UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other 
     routes inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Vision UI Dashboard React layouts
import Dashboard from "layouts/dashboard";
//
import Links from "layouts/admin/DashLinks";
import Printers from "layouts/admin/DashPrinters";
import SecurityDevices from "layouts/admin/DashSecurityDevice";
import ClockTimes from "layouts/admin/DashClockTime";
import Units from "layouts/admin/DashUnits";

import Voips from "layouts/voips";
import Maps from "layouts/maps";
import Login from "layouts/admin";
import Admin from "layouts/admin/DashAdmin";
import DashOccurrence from "layouts/admin/DashOccurrence";

import OccurrenceLink from "layouts/occurrenceLink";
import OccurrenceView from "layouts/occurrenceView";

import Devices from "layouts/devices";
import Support from "layouts/support";

import Reset from "layouts/reset";

// import Maps2 from "layouts/maps2";
// import Billing from "layouts/billing";

// Vision UI Dashboard React icons
import { IoFlash } from "react-icons/io5";
import { IoCall } from "react-icons/io5";
import { IoHome } from "react-icons/io5";
import { IoPrintSharp } from "react-icons/io5";
import { IoMap } from "react-icons/io5";
import { IoLogIn } from "react-icons/io5";
import { BiSupport } from "react-icons/bi";

// import { IoCardSharp } from "react-icons/io5";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <IoHome size="15px" color="inherit" />,
    component: Dashboard,
    noCollapse: true,
    nodeType: "public",
  },
  {
    type: "collapse",
    name: "Ramais Voips",
    key: "voips",
    route: "/voips",
    icon: <IoCall size="15px" color="inherit" />,
    component: Voips,
    noCollapse: true,
    nodeType: "public",
  },
  {
    type: "title",
    name: "Login",
    key: "login",
    route: "/login",
    icon: <IoLogIn size="22px" color="inherit" />,
    component: Login,
    noCollapse: true,
    nodeType: "public",
  },
  {
    type: "title",
    name: "Links",
    key: "links",
    route: "/links",
    icon: <IoFlash size="15px" color="inherit" />,
    component: Links,
    noCollapse: true,
    nodeType: "private",
  },
  {
    type: "title",
    name: "Impressoras",
    key: "printers",
    route: "/printers",
    icon: <IoPrintSharp size="15px" color="inherit" />,
    component: Printers,
    noCollapse: true,
    nodeType: "private",
  },
  {
    type: "title",
    name: "SecurityDevices",
    key: "securityDevices",
    route: "/securityDevice",
    icon: <IoPrintSharp size="15px" color="inherit" />,
    component: SecurityDevices,
    noCollapse: true,
    nodeType: "private",
  },
  {
    type: "title",
    name: "ClockTimes",
    key: "clockTimes",
    route: "/clocktimes",
    icon: <IoPrintSharp size="15px" color="inherit" />,
    component: ClockTimes,
    noCollapse: true,
    nodeType: "private",
  },
  {
    type: "collapse",
    name: "Mapa",
    key: "maps",
    route: "/maps",
    icon: <IoMap size="15px" color="inherit" />,
    component: Maps,
    noCollapse: true,
    nodeType: "public",
  },
  {
    type: "title",
    name: "AdminDash",
    key: "adminDash",
    route: "/admin",
    icon: <IoMap size="15px" color="inherit" />,
    component: Admin,
    noCollapse: true,
    nodeType: "private",
  },
  {
    type: "title",
    name: "DashOccurrence",
    key: "DashOccurrence",
    route: "/DashOccurrence",
    icon: <IoMap size="15px" color="inherit" />,
    component: DashOccurrence,
    noCollapse: true,
    nodeType: "private",
  },
  {
    type: "title",
    name: "OccurrenceLink",
    key: "OccurrenceLink",
    route: "/occurrencelink",
    icon: <IoMap size="15px" color="inherit" />,
    component: OccurrenceLink,
    noCollapse: true,
    nodeType: "private",
  },
  {
    type: "title",
    name: "OccurrenceView",
    key: "OccurrenceView",
    route: "/occurrenceview",
    icon: <IoMap size="15px" color="inherit" />,
    component: OccurrenceView,
    noCollapse: true,
    nodeType: "public",
  },
  {
    type: "title",
    name: "Units",
    key: "Units",
    route: "/units",
    icon: <IoMap size="15px" color="inherit" />,
    component: Units,
    noCollapse: true,
    nodeType: "private",
  },
  {
    type: "title",
    name: "Suporte",
    key: "Support",
    route: "/support",
    icon: <BiSupport size="15px" color="inherit" />,
    component: Support,
    noCollapse: true,
    nodeType: "private",
  },
  {
    type: "title",
    name: "Reset",
    key: "Reset",
    route: "/reset",
    icon: <BiSupport size="15px" color="inherit" />,
    component: Reset,
    noCollapse: true,
    nodeType: "public",
  },
  {
    type: "title",
    name: "Devices",
    key: "Devices",
    route: "/devices",
    icon: <BiSupport size="15px" color="inherit" />,
    component: Devices,
    noCollapse: true,
    nodeType: "public",
  },
  // {
  //   type: "collapse",
  //   name: "Mapa v2",
  //   key: "maps2",
  //   route: "/maps2",
  //   icon: <IoMap size="15px" color="inherit" />,
  //   component: Maps2,
  //   noCollapse: true,
  // nodeType: "public",
  // },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   route: "/billing",
  //   icon: <IoCardSharp size="15px" color="inherit" />,
  //   component: Billing,
  //   noCollapse: true,
  // nodeType: "public",
  // },
];

export default routes;
