import React, { useState } from "react";

import SelectComponent, { Props, InnerRef } from "react-select";

import { Container } from "./styles";

const customStyles = {
  menuList: (styles) => ({
    ...styles,
    background: "papayawhip",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected } = state) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? data.color
        : isFocused
        ? "rgba(189,197,209,.3)"
        : undefined,
      color: isDisabled ? "rgba(189,197,209,.3)" : "#fff",
      cursor: isDisabled ? "not-allowed" : "default",
      fontSize: 14,
      textAlign: "start",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled ? (isSelected ? data.color : undefined) : undefined,
      },
    };
  },
};

const VuiSelect = React.forwardRef(
  ({ name, label, placeholder, onCustomChange, options, styles = customStyles, ...rest }, ref) => {
    const [state, setState] = useState({
      isFocused: false,
    });

    return (
      <Container isFocused={state.isFocused} data-testid="selectContainer">
        <label htmlFor={name} aria-labelledby="label" style={{ textAlign: "start" }}>
          {label}
        </label>
        <SelectComponent
          aria-labelledby="select"
          aria-label="select"
          className="select"
          classNamePrefix="select"
          noOptionsMessage={() => "Opção não encontrada."}
          isClearable={true}
          isMulti={false}
          isSearchable
          defaultValue={rest.defaultValue}
          onFocus={() => setState({ ...state, isFocused: true })}
          onBlur={() => setState({ ...state, isFocused: false })}
          inputId={name}
          options={options}
          placeholder={placeholder}
          onChange={onCustomChange}
          styles={styles}
          {...rest}
          ref={ref}
        />
      </Container>
    );
  }
);

export default VuiSelect;
