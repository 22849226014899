import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

import Icon from "@mui/material/Icon";

function VuiLabel({
  title,
  colorTitle = "white",
  sizeTitle = 12,
  fontWeightTitle = "regular",
  textAlignTitle = "left",
  noWrapTitle = false,
  styleTitle = {},
  //
  subtitle,
  colorSubTitle = "white",
  sizeSubTitle = 14,
  fontWeightSubTitle = "regular",
  noWrapSubTitle = false,
  //
  openLinkClick = null,
  iconLinkClick = "open_in_new",
  onClick = null,
  iconOnClick = "open_in_new",
}) {
  return (
    <VuiBox display="flex" alignItems="center">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <VuiTypography
          variant="button"
          color={colorTitle}
          fontWeight={fontWeightTitle}
          sx={{ fontSize: sizeTitle, textAlign: textAlignTitle, ...styleTitle }}
          noWrap={noWrapTitle}
        >
          {title}
        </VuiTypography>
        {openLinkClick ? (
          <VuiTypography
            component="a"
            target="_blank"
            href={openLinkClick}
            variant="sm"
            color={colorSubTitle}
            fontWeight={fontWeightSubTitle}
            sx={{
              fontSize: sizeSubTitle,
              display: "inline-flex",
              alignItems: "center",
              cursor: "pointer",
              "& .material-icons-round": {
                fontSize: "1.125rem",
                transform: `translate(2px, -0.5px)`,
                transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
              },
              "&:hover .material-icons-round, &:focus .material-icons-round": {
                transform: `translate(6px, -0.5px)`,
              },
            }}
            noWrap={noWrapSubTitle}
          >
            &#160;{subtitle}
            <Icon fontSize="normal" sx={{ fontWeight: "bold", mr: "0px", ml: "5px" }}>
              {iconLinkClick}
            </Icon>
          </VuiTypography>
        ) : onClick ? (
          <VuiTypography
            onClick={onClick}
            target="_blank"
            variant="sm"
            color={colorSubTitle}
            fontWeight={fontWeightSubTitle}
            sx={{
              fontSize: sizeSubTitle,
              display: "inline-flex",
              alignItems: "center",
              cursor: "pointer",
              "& .material-icons-round": {
                fontSize: "1.125rem",
                transform: `translate(2px, -0.5px)`,
                transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
              },
              "&:hover .material-icons-round, &:focus .material-icons-round": {
                transform: `translate(6px, -0.5px)`,
              },
            }}
            noWrap={noWrapSubTitle}
          >
            &#160;{subtitle}
            <Icon fontSize="small" sx={{ fontWeight: "bold", mr: "0px", ml: "5px" }}>
              {iconOnClick}
            </Icon>
          </VuiTypography>
        ) : (
          <VuiTypography
            variant="button"
            color={colorSubTitle}
            fontWeight={fontWeightSubTitle}
            sx={{ fontSize: sizeSubTitle }}
            noWrap={noWrapSubTitle}
          >
            &#160;{subtitle}
          </VuiTypography>
        )}
      </div>
    </VuiBox>
  );
}

export default VuiLabel;
